<i18n>
ru:
  count: 'Нет позиций | {n} позиция | {n} позиции | {n} позиций'
  fromPrice: от
ua:
  count: 'Нет позиций | {n} позиция | {n} позиции | {n} позиций'
  fromPrice: від
us:
  count: 'no items | one item | {count} items'
  fromPrice: from
</i18n>

<template>
  <div
    v-if="isHeader"
    class="v-w-100"
    v-sticky-top="{
      refresh: false,
      onRefresh: updateSticky
    }"
  >
    <menu-groups-barad-nimras
      v-if="appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradNimras'"
      :selected-group-id="selectedGroup?.ID"
    />
    <menu-groups-barrow-downs
      v-else-if="
        !(stickyLocal || !isDesktopVersion) &&
        appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BarrowDowns'
      "
      :selected-group-id="selectedGroup?.ID"
    />
    <menu-groups-barad-dur
      v-else-if="appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BaradDur'"
      :selected-group-id="selectedGroup?.ID"
    />
    <menu-groups-orthanc
      v-else-if="
        appConfig.VueSettingsPreRun.MenuGroupsLayout === 'Orthanc' ||
        ((stickyLocal || !isDesktopVersion) &&
          appConfig.VueSettingsPreRun.MenuGroupsLayout === 'BarrowDowns')
      "
      :selected-group-id="selectedGroup?.ID"
    />
  </div>
  <template v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsShowImages">
    <menu-groups-main-page-isengard
      v-if="appConfig.VueSettingsPreRun.MenuMainGroupsLayout === 'Isengard'"
    />
    <menu-groups-main-page-helms-deep
      v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsLayout === 'HelmsDeep'"
    />
    <menu-groups-main-page-henneth-annun
      v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsLayout === 'HennethAnnun'"
    />
  </template>
</template>

<script setup lang="ts">
import { Guid } from '~api/consts'

import type { Group } from '~types/menuStore'

import { type GUID, useWindowSize, vStickyTop } from '@arora/common'

defineProps<{
  isHeader?: boolean
}>()

const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { isDesktopVersion } = useWindowSize()

const stickyLocal = ref<boolean>(false)
function updateSticky(isSticky: boolean): void {
  stickyLocal.value = isSticky
}

onMounted(async () => {
  await initGroups()
})

async function initGroups(): Promise<void> {
  if (Guid.IsNullOrEmpty(menuStore.CurrentGroupId)) {
    selectedGroup.value = null
    menuStore.CurrentSubgroupId = Guid.Empty

    return
  }

  const currentGroup = appConfig.Groups?.find((group) => group.ID === menuStore.CurrentGroupId)

  if (currentGroup) {
    if (Guid.IsNullOrEmpty(currentGroup.ParentGroupID as GUID)) {
      selectedGroup.value = currentGroup
      menuStore.CurrentSubgroupId = currentGroup.ID
    } else {
      selectedGroup.value = appConfig.Groups?.find((group) => group.ID === currentGroup?.ParentGroupID)
      menuStore.CurrentSubgroupId = currentGroup.ID
    }
  }
}

watch(() => menuStore.CurrentGroupId, initGroups)

const selectedGroup = ref<Group | null | undefined>()
</script>
